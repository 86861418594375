import React, {useCallback, useMemo, useRef} from "react";
import PropTypes from "prop-types";
import "./style.less";

const CodeInput = ({length = 6, loading = false, onChange, value = ""}) => {
  const inputsRef = useRef([]);

  const codeArray = useMemo(() => {
    const chars = Array.from(value);
    return chars.length >= length
      ? chars.slice(0, length)
      : [...chars, ...Array(length - chars.length).fill("")];
  }, [value, length]);

  const handleInputChange = useCallback(
    (e, index) => {
      const inputValue = e.target.value;
      if (/\D/.test(inputValue)) {
        return;
      }

      const newCode = [...codeArray];
      newCode[index] = inputValue;
      onChange(newCode.join(""));
      if (inputValue && index < length - 1) {
        inputsRef.current[index + 1]?.focus();
      }
    },
    [codeArray, onChange, length]
  );

  const handleKeyUp = useCallback(
    (e, index) => {
      if (e.key === "Backspace" && !codeArray[index] && index > 0) {
        const newCode = [...codeArray];
        newCode[index - 1] = "";
        onChange(newCode.join(""));
        inputsRef.current[index - 1]?.focus();
      }
    },
    [codeArray, onChange]
  );

  return (
    <div className="code-input">
      <div className="code-inputs">
        {codeArray.map((digit, index) => (
          <input
            key={index}
            type="text"
            inputMode="numeric"
            maxLength={1}
            value={digit}
            autoFocus={!codeArray[0].length && index === 0}
            readOnly={loading}
            placeholder="·"
            className={`code-field ${digit ? "filled" : ""}`}
            onChange={(e) => handleInputChange(e, index)}
            onKeyUp={(e) => handleKeyUp(e, index)}
            ref={(el) => (inputsRef.current[index] = el)}
          />
        ))}
      </div>
    </div>
  );
};

CodeInput.propTypes = {
  length: PropTypes.number,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default CodeInput;
