/* eslint-disable no-console */
/* eslint-disable no-debugger */
import React, {useCallback, useEffect} from "react";
import {Button, Form, Input} from "antd";
import i18next from "i18next";
import * as Setting from "../../../../Setting";

const RegistrationForm = ({application, loading, onSubmit, initialValues}) => {
  const [form] = Form.useForm();
  console.log(form);

  const validateEmail = useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [form, initialValues]);

  const renderFormItem = useCallback((application, signupItem) => {
    const validItems = ["Gender", "Bio", "Tag", "Education"];
    if (!signupItem?.visible) {
      return null;
    }

    const required = signupItem.required;

    if (signupItem.name === "Username") {
      return (
        <Form.Item
          key={signupItem?.name}
          name="username"
          className="signup-username"
          label={signupItem.label ? signupItem.label : i18next.t("signup:Username")}
          rules={[
            {
              required: required,
              message: i18next.t("signup:Please input your username!"),
              whitespace: true,
            },
          ]}
        >
          <Input placeholder={signupItem.placeholder} />
        </Form.Item>
      );
    } else if (signupItem.name === "First Name") {
      return (
        <Form.Item
          name="firstName"
          className="signup-first-name"
          label={signupItem.label ? signupItem.label : i18next.t("signup:First Name")}
          rules={[
            {
              required: required,
              message: i18next.t("signup:Please input your first name!"),
              whitespace: true,
            },
          ]}
        >
          <Input placeholder={signupItem.placeholder} />
        </Form.Item>
      );
    } else if (signupItem.name === "Last Name") {
      return (
        <Form.Item
          name="lastName"
          className="signup-last-name"
          label={signupItem.label ? signupItem.label : i18next.t("signup:Last Name")}
          rules={[
            {
              required: required,
              message: i18next.t("signup:Please input your last name!"),
              whitespace: true,
            },
          ]}
        >
          <Input placeholder={signupItem.placeholder} />
        </Form.Item>
      );
    } else if (signupItem.name === "Patronymic") {
      return (
        <Form.Item
          name="patronymic"
          className="signup-patronymic"
          label={signupItem.label ? signupItem.label : i18next.t("signup:Patronymic")}
          rules={[
            {
              required: required,
              message: i18next.t("signup:Please input your patronymic!"),
              whitespace: true,
            },
          ]}
        >
          <Input placeholder={signupItem.placeholder} />
        </Form.Item>
      );
    } else if (signupItem.name === "Email") {
      return (
        <Form.Item
          name="email"
          className="signup-email"
          label={signupItem.label ? signupItem.label : i18next.t("signup:Email")}
          rules={[
            {
              required: required,
              message: i18next.t("user:Input your email"),
              whitespace: true,
            },
            {
              validator: (_, value) => {
                if (!value) {return Promise.resolve();}
                if (!validateEmail(value)) {
                  return Promise.reject(new Error("Неверный формат email"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder={signupItem.placeholder} />
        </Form.Item>
      );
    } else if (validItems.includes(signupItem.name)) {
      return renderFormItem(signupItem);
    }
  }, [validateEmail]);

  const handleSubmit = useCallback(async() => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const hasEmptyValues = Object.entries(values).some(([key, value]) => {
        if (value === undefined || value === null || value === "") {
          return true;
        }
        return false;
      });

      if (hasEmptyValues) {
        Setting.showMessage("error", "Пожалуйста, заполните все обязательные поля");
        return;
      }

      onSubmit(values);
    } catch (error) {
      Setting.showMessage("error", "Пожалуйста, проверьте правильность заполнения всех полей");
    }
  }, [form, onSubmit]);

  return (
    <Form
      form={form}
      className="registration-form"
      layout="vertical"
      initialValues={initialValues}
    >
      <div className="registration-form__header-container">
        <h3 className="registration-form__header">Заполните данные для регистрации</h3>
        {application?.signupItems?.map((item) => renderFormItem(application, item))}
      </div>

      <Button
        type="primary"
        size="large"
        block
        loading={loading}
        onClick={handleSubmit}
        className="button registration-form__submit-button"
      >
          Продолжить
      </Button>
    </Form>
  );
};

export default RegistrationForm;
