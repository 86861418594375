/* eslint-disable no-console */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import * as AuthBackend from "../AuthBackend";
import * as Setting from "../../Setting";
import * as Util from "../Util";
import * as UserBackend from "../../backend/UserBackend";
import {formatIncompletePhoneNumber, parsePhoneNumber} from "libphonenumber-js";
import logoImage from "../../assets/logo_wt-min.png";
import "./style.less";
import CodeInput from "./components/CodeInput/CodeInput";
import PatternInput from "./components/PatternInput/PatternInput";
import {CaptchaModal} from "../../common/modal/CaptchaModal";
import i18next from "i18next";
import {Button, Checkbox, Form, Input, Tabs} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import RegistrationForm from "./components/RegistrationForm/RegistrationForm";

i18next.changeLanguage("ru");

const CustomLoginPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState("phone");
  const formRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [loginMethod, setLoginMethod] = useState("password");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [autoSignin, setAutoSignin] = useState(false);
  const [application, setApplication] = useState(props.application);
  const [account, setAccount] = useState(props.account);
  const [oAuthParams, setOAuthParams] = useState(null);
  const [captchaVisible, setCaptchaVisible] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [availableLoginMethods, setAvailableLoginMethods] = useState([]);
  const [registrationData, setRegistrationData] = useState(null);
  const codeResponseRef = useRef(null);

  const validatePhone = useCallback((phone) => {
    const numericValue = phone.replace(/[\D\s]*/gi, "");
    return (
      (numericValue.length === 10 && !numericValue.startsWith("7")) ||
      (numericValue.startsWith("7") && numericValue.length === 11)
    );
  }, []);

  const validateEmail = useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  const validatePassword = useCallback((password) => {
    return password.length >= 6;
  }, []);

  const formatPhone = useCallback((phone) => {
    let numericValue = phone.replace(/[\D\s]*/gi, "");
    if (numericValue.startsWith("7") || numericValue.startsWith("8")) {
      numericValue = numericValue.slice(1);
    }
    return numericValue;
  }, []);

  const formatPhoneWithCountryCode = useCallback((phone) => {
    try {
      const phoneNumber = parsePhoneNumber(phone, "RU");
      if (phoneNumber) {
        return phoneNumber.formatInternational();
      }
      return formatIncompletePhoneNumber(phone, "RU");
    } catch (error) {
      console.error("Error formatting phone number:", error);
      return phone;
    }
  }, []);

  const handleRegistrationSubmit = useCallback(async(values) => {
    if (account) {
      // Если у нас есть account, значит это обновление существующего пользователя
      setRegistrationData(values);
      const hasAgreements = application?.signupItems?.some(item => item.name === "Agreement");
      if (hasAgreements) {
        setStage("agreements");
      } else {
        // Если нет соглашений, сразу обновляем пользователя
        try {
          const updateValues = {
            ...account,
            ...values,
            displayName: [values.lastName, values.firstName, values.patronymic].filter(Boolean).join(" "),
          };
          const res = await AuthBackend.updateUser(updateValues);
          if (res.status === "ok") {
            Setting.showMessage("success", "Данные успешно обновлены");
            handleLoginResponse(res, "", "updateUser");
          } else {
            Setting.showMessage("error", `Ошибка обновления: ${i18next.t(res.msg)}`);
          }
        } catch (err) {
          Setting.showMessage("error", `Ошибка обновления: ${i18next.t(err.message)}`);
        }
      }
    } else {
      // Если account нет, значит это новая регистрация
      setRegistrationData(values);
      const hasAgreements = application?.signupItems?.some(item => item.name === "Agreement");
      if (hasAgreements) {
        setStage("agreements");
      } else {
        // Если нет соглашений, сразу регистрируем
        const phone = formatPhone(phoneNumber);
        const registerValues = {
          application: application?.name,
          organization: application?.organization,
          name: [values.lastName, values.firstName, values.patronymic].filter(Boolean).join(" "),
          phone: phone,
          phonePrefix: "+7",
          countryCode: "RU",
          phoneCode: code,
          type: oAuthParams?.responseType || oAuthParams?.type || "login",
          ...values,
        };
        try {
          const res = await AuthBackend.loginAutoRegister(registerValues, oAuthParams);
          handleLoginResponse(res, "", "login");
        } catch (err) {
          Setting.showMessage("error", `Ошибка регистрации: ${i18next.t(err.message)}`);
        }
      }
    }
  }, [account, application, code, formatPhone, handleLoginResponse, oAuthParams, phoneNumber]);

  const handleAgreementsSubmit = useCallback(async() => {
    const checkboxes = formRef.current.querySelectorAll("input[type=\"checkbox\"]");
    const agreements = {};
    let allChecked = true;

    checkboxes.forEach(checkbox => {
      if (checkbox.name) {
        agreements[checkbox.name] = checkbox.checked;
        if (!checkbox.checked) {
          allChecked = false;
        }
      }
    });

    if (!allChecked) {
      Setting.showMessage("error", "Необходимо принять все условия использования");
      return;
    }

    if (account) {
      // Если у нас есть account, обновляем пользователя
      try {
        const updateValues = {
          ...registrationData,
          name: [registrationData.lastName, registrationData.firstName, registrationData.patronymic].filter(Boolean).join(" "),
          agreements: agreements,
        };
        const res = await AuthBackend.updateUser(updateValues);
        if (res.status === "ok") {
          Setting.showMessage("success", "Данные успешно обновлены");
          handleLoginResponse(res, "", "updateUser");
        } else {
          Setting.showMessage("error", `Ошибка обновления: ${i18next.t(res.msg)}`);
        }
      } catch (err) {
        Setting.showMessage("error", `Ошибка обновления: ${i18next.t(err.message)}`);
      }
    } else {
      // Если account нет, регистрируем нового пользователя
      const phone = formatPhone(phoneNumber);
      const values = {
        application: application?.name,
        organization: application?.organization,
        name: [registrationData.lastName, registrationData.firstName, registrationData.patronymic].filter(Boolean).join(" "),
        phone: phone,
        phonePrefix: "+7",
        countryCode: "RU",
        phoneCode: code,
        type: oAuthParams?.responseType || oAuthParams?.type || "login",
        agreements: agreements,
        ...registrationData,
      };
      try {
        const res = await AuthBackend.loginAutoRegister(values, oAuthParams);
        handleLoginResponse(res, "", "login");
      } catch (err) {
        Setting.showMessage("error", `Ошибка регистрации: ${i18next.t(err.message)}`);
      }
    }
  }, [account, application, code, formatPhone, handleLoginResponse, oAuthParams, phoneNumber, registrationData]);

  const handleLoginRequest = useCallback(async(values) => {
    try {
      const res = await AuthBackend.login(values, oAuthParams);
      return res;
    } catch (err) {
      Setting.showMessage("error", `Ошибка: ${err.message}`);
      return null;
    }
  }, [oAuthParams]);

  const handleLoginResponse = useCallback(async(res, username = "", source = "login") => {
    debugger;
    if (res?.status === "ok") {
      if (res.data === "NextMfa") {
        Setting.showMessage("error", "MFA не поддерживается в данный момент");
      } else if (res.data === "SelectPlan") {
        const pricing = res.data2;
        Setting.goToLink(`/select-plan/${pricing.owner}/${pricing.name}?user=${username}`);
      } else if (res.data === "BuyPlanResult") {
        const sub = res.data2;
        Setting.goToLink(`/buy-plan/${sub.owner}/${sub.pricing}/result?subscription=${sub.name}`);
      } else {
        if (!oAuthParams?.redirectUri) {
          Setting.showMessage("success", "Успешный вход в систему");
          props.onLoginSuccess();
          return;
        }

        // Сохраняем codeResponse только если это ответ от login
        if (source === "login") {
          codeResponseRef.current = res.data;
        }
        try {
          // Получаем данные аккаунта после успешного логина
          const accountRes = await AuthBackend.getAccount("");
          if (accountRes.status === "ok") {
            if (loginMethod === "phone") {

              const account = accountRes.data;
              setAccount(account);

              // Маппинг названий полей
              const fieldNameMapping = {
                "ID": "id",
                "First Name": "firstName",
                "Last Name": "lastName",
                "Patronymic": "patronymic",
                "Email": "email",
              };

              // Проверяем обязательные поля из authForm
              const requiredFields = application?.signupItems?.filter(item => item.required) || [];
              const hasEmptyFields = requiredFields.some(field => {
                const mappedFieldName = fieldNameMapping[field.name] || field.name.toLowerCase().replace(/\s+/g, "");
                return !account[mappedFieldName] || account[mappedFieldName].trim() === "";
              });

              if (hasEmptyFields) {
                // Если есть пустые обязательные поля, показываем форму регистрации
                setRegistrationData(account);
                setStage("registration");
                return;
              }

              // Если все поля заполнены, продолжаем стандартный процесс
              if (!oAuthParams?.redirectUri) {
                Setting.showMessage("success", "Успешный вход в систему");
                props.onLoginSuccess();
                return;
              }
            }
            debugger;
            const concatChar = oAuthParams.redirectUri.includes("?") ? "&" : "?";
            const redirectUrl = `${oAuthParams.redirectUri}${concatChar}code=${codeResponseRef.current}&state=${oAuthParams.state}`;
            Setting.goToLink(redirectUrl);
          }
        } catch (err) {
          Setting.showMessage("error", `Ошибка получения данных аккаунта: ${err.message}`);
        }
      }
    } else if (res) {
      Setting.showMessage("error", `Ошибка входа: ${i18next.t(res.msg)}`);
    }
  }, [oAuthParams, props, application]);

  const handleCaptchaOk = useCallback(async(type, token, secret) => {
    setCaptchaVisible(false);
    setLoading(true);
    const phone = formatPhone(phoneNumber);
    try {
      const res = await UserBackend.sendCode(
        type,
        token,
        secret,
        "register",
        "RU",
        phone,
        "phone",
        `${application?.owner}/${application?.name}`,
        ""
      );
      if (res) {
        setStage("code");
        setIsResendDisabled(true);
        setResendTimer(60);
      } else {
        Setting.showMessage("error", res.msg);
      }
    } catch (err) {
      Setting.showMessage("error", `Ошибка отправки кода: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [phoneNumber, application, formatPhone]);

  const handleCaptchaCancel = useCallback(() => {
    setCaptchaVisible(false);
  }, []);

  const handlePhoneSubmit = useCallback(async() => {
    if (!validatePhone(phoneNumber)) {
      Setting.showMessage("error", "Неверный формат номера телефона");
      return;
    }

    if (loading) {
      return;
    }

    setLoading(true);
    try {
      const captchaData = await UserBackend.getCaptcha(application?.owner, application?.name, false);
      if (captchaData.type === "none") {
        const phone = formatPhone(phoneNumber);
        const res = await UserBackend.sendCode(
          "none",
          "",
          "",
          "register",
          "RU",
          phone,
          "phone",
          `${application?.owner}/${application?.name}`,
          ""
        );
        if (res) {
          setStage("code");
          setIsResendDisabled(true);
          setResendTimer(60);
        } else {
          Setting.showMessage("error", res.msg);
        }
      } else {
        setCaptchaVisible(true);
      }
    } catch (err) {
      Setting.showMessage("error", `Ошибка отправки кода: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [phoneNumber, application, formatPhone, validatePhone, loading]);

  const handleCodeSubmit = useCallback(async() => {
    if (!code) {
      Setting.showMessage("error", "Введите код");
      return;
    }

    setLoading(true);
    const phone = formatPhone(phoneNumber);

    try {
      const checkResult = await AuthBackend.checkUserByEmailOrPhone(
        application?.organization,
        phone,
        code,
        "phone",
        "RU"
      );
      if (checkResult.status === "error") {
        throw new Error(checkResult.msg);
      }
      if (checkResult.data === true) {
        // Существующий пользователь - продолжаем старую логику
        const values = {
          application: application?.name,
          organization: application?.organization,
          phone: phone,
          phonePrefix: "+7",
          countryCode: "RU",
          phoneCode: code,
          autoSignin: autoSignin,
          type: oAuthParams?.responseType || oAuthParams?.type || "login",
        };
        const res = await AuthBackend.loginAutoRegister(values, oAuthParams);
        handleLoginResponse(res, "", "login");
      } else {
        // Новый пользователь и разрешена регистрация - показываем форму регистрации
        if (application.enableSignUp) {
          setStage("registration");
        } else {
          throw new Error("application:The application does not allow to sign up new account");
        }
      }
    } catch (err) {
      Setting.showMessage("error", `Ошибка входа: ${i18next.t(err.message)}`);
    }
    setLoading(false);
  }, [code, phoneNumber, application, oAuthParams, handleLoginResponse, formatPhone, autoSignin]);

  const handlePasswordSubmit = useCallback(async() => {
    if (!validateEmail(email)) {
      Setting.showMessage("error", "Неверный формат email");
      return;
    }

    if (!validatePassword(password)) {
      Setting.showMessage("error", "Пароль должен быть не менее 6 символов");
      return;
    }

    setLoading(true);
    const values = {
      application: application?.name,
      organization: application?.organization,
      username: email,
      password: password,
      autoSignin: autoSignin,
      signinMethod: "Password",
      type: oAuthParams?.responseType || oAuthParams?.type || "login",
    };
    const res = await handleLoginRequest(values);
    handleLoginResponse(res, email, "login");
    setLoading(false);
  }, [email, password, application, oAuthParams, handleLoginRequest, handleLoginResponse, validateEmail, validatePassword, autoSignin]);

  const handlePhoneChange = useCallback((e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    const numericValue = value.replace(/[\D\s]*/gi, "");
    setIsSubmitButtonDisabled(
      !((numericValue.length === 10 && !numericValue.startsWith("7")) ||
      (numericValue.startsWith("7") && numericValue.length === 11))
    );
  }, []);

  useEffect(() => {
    let timer;
    if (isResendDisabled && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    if (resendTimer === 0) {
      setIsResendDisabled(false);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isResendDisabled, resendTimer]);

  const handleResendCode = useCallback(async() => {
    setIsResendDisabled(true);
    setResendTimer(60);
    try {
      const captchaData = await UserBackend.getCaptcha(application?.owner, application?.name, false);
      if (captchaData.type === "none") {
        const res = await UserBackend.sendCode(
          "none",
          "",
          "",
          "register",
          "RU",
          formatPhone(phoneNumber),
          "phone",
          `${application?.owner}/${application?.name}`,
          ""
        );
        if (res.status === "ok") {
          Setting.showMessage("success", "Код отправлен повторно");
        } else {
          Setting.showMessage("error", res.msg);
        }
      } else {
        setCaptchaVisible(true);
      }
    } catch (err) {
      Setting.showMessage("error", `Ошибка отправки кода: ${err.message}`);
      setIsResendDisabled(false);
      setResendTimer(0);
    }
  }, [application?.owner, application?.name, formatPhone, phoneNumber]);

  const determineAvailableLoginMethods = useCallback(() => {
    if (!application?.signinMethods?.length) {
      return ["password", "phone"]; // По умолчанию разрешаем оба метода
    }

    const methods = [];
    application.signinMethods.forEach(method => {
      if (method.name === "Password") {
        methods.push("password");
      } else if (method.name === "Verification code") {
        if (method.rule === "Phone only") {
          methods.push("phone");
        } else if (method.rule === "Email only") {
          methods.push("password");
        } else {
          methods.push("password", "phone");
        }
      }
    });

    return methods;
  }, [application]);

  useEffect(() => {
    const methods = determineAvailableLoginMethods();
    setAvailableLoginMethods(methods);
    // Устанавливаем начальный метод входа
    if (methods.length === 1) {
      setLoginMethod(methods[0]);
    }
  }, [application, determineAvailableLoginMethods]);

  useEffect(() => {
    document.body.setAttribute("data-page", "custom-login");
    document.documentElement.style.setProperty("--logo-image", `url(${logoImage})`);

    const params = new URLSearchParams(window.location.search);
    const type = params.get("type") || "login";

    const oauthParams = Util.getOAuthGetParameters();
    if (oauthParams?.clientId) {
      setOAuthParams({
        clientId: oauthParams.clientId,
        responseType: oauthParams.responseType,
        redirectUri: oauthParams.redirectUri,
        scope: oauthParams.scope,
        state: oauthParams.state,
        nonce: oauthParams.nonce,
        challengeMethod: oauthParams.challengeMethod,
        codeChallenge: oauthParams.codeChallenge,
        type: type,
      });
    } else if (type === "code" && window.location.pathname.includes("/login")) {
      Setting.showMessage("error", "Ошибка: отсутствуют необходимые параметры OAuth");
    }

    return () => {
      document.body.removeAttribute("data-page");
      document.documentElement.style.removeProperty("--logo-image");
    };
  }, []);

  useEffect(() => {
    setAccount(props.account);
  }, [props.account]);

  useEffect(() => {
    setApplication(props.application);
  }, [props.application]);

  useEffect(() => {
    if (props.account) {
      setStage("confirmation");
    }
  }, [props.account]);

  const handleUserConfirmation = useCallback(async() => {
    setLoading(true);
    try {
      const values = {
        application: application?.name,
        organization: application?.organization,
        autoSignin: true,
        type: oAuthParams?.responseType || oAuthParams?.type || "login",
      };
      const res = await handleLoginRequest(values);
      handleLoginResponse(res, props.account.name, "login");
    } catch (err) {
      Setting.showMessage("error", `Ошибка входа: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [application, oAuthParams, handleLoginRequest, handleLoginResponse, props.account]);

  const renderPhoneForm = useCallback(() => (
    <Form className="login-phone-form">
      <div className="login-phone-form__mobile-spacer" />
      <div className="login-phone-form__logo" />
      <div>
        <h3 className="login-phone-form__header">Войти в личный кабинет</h3>
      </div>
      <div>
        <Form.Item>
          <PatternInput
            value={phoneNumber}
            onChange={handlePhoneChange}
            placeholder="+7 (___) ___-__-__"
          />
        </Form.Item>

        <Form.Item>
          <Checkbox className="login-phone-form__auto_sign" checked={autoSignin} onChange={(e) => setAutoSignin(e.target.checked)}>
            Запомнить меня
          </Checkbox>
        </Form.Item>

        <Button
          type="primary"
          size="large"
          block
          disabled={isSubmitButtonDisabled}
          loading={loading}
          onClick={handlePhoneSubmit}
          className="button login-phone-form__submit-button"
        >
          Получить код
        </Button>
      </div>
    </Form>
  ), [phoneNumber, handlePhoneChange, isSubmitButtonDisabled, loading, handlePhoneSubmit, autoSignin]);

  const renderCodeForm = useCallback(() => (
    <>
      <Button
        type="text"
        className="back-button"
        onClick={() => setStage("phone")}
        icon={<ArrowLeftOutlined />}
      />
      <Form className="login-sms-code-form">

        <div className="login-sms-code-form__logo" />
        <div className="login-sms-code-form__main-content">
          <p className="login-sms-code-form__description">
          Мы отправили код на номер {phoneNumber}
          </p>
          <Form.Item>
            <CodeInput value={code} onChange={setCode} />
          </Form.Item>
          <div className="login-sms-code-form__actions">
            <Button
              className="button"
              disabled={isResendDisabled}
              onClick={handleResendCode}
            >
              {isResendDisabled
                ? `Отправить код повторно (${resendTimer}с)`
                : "Отправить код повторно"}
            </Button>
          </div>
        </div>
        <Button
          type="primary"
          size="large"
          block
          loading={loading}
          onClick={handleCodeSubmit}
          className="button login-sms-code-form__submit-button"
        >
          Войти
        </Button>
      </Form>
    </>
  ), [phoneNumber, isResendDisabled, resendTimer, handleResendCode, code, loading, handleCodeSubmit]);

  const renderRegistrationForm = useCallback(() => (
    <>
      <Button
        type="text"
        className="back-button"
        onClick={() => setStage("code")}
        icon={<ArrowLeftOutlined />}
      />
      <RegistrationForm
        application={application}
        loading={loading}
        onSubmit={handleRegistrationSubmit}
        initialValues={registrationData}
      />
    </>
  ), [application, loading, handleRegistrationSubmit, registrationData]);

  const renderAgreementsForm = useCallback(() => (
    <>
      <Button
        type="text"
        className="back-button"
        onClick={() => setStage("registration")}
        icon={<ArrowLeftOutlined />}
      />
      <div className="agreement-form">

        <div
          ref={formRef}
          dangerouslySetInnerHTML={{__html: application.agreementHtml}}
          className="agreement-form-container"
        />
        <Button
          type="primary"
          size="large"
          block
          loading={loading}
          onClick={handleAgreementsSubmit}
          className="button agreement-form__submit-button"
        >
        Продолжить
        </Button>
      </div>
    </>
  ), [application.agreementHtml, loading, handleAgreementsSubmit]);

  const renderPasswordForm = useCallback(() => (
    <Form className="login-phone-form">
      <div className="login-phone-form__mobile-spacer" />
      <div className="login-phone-form__logo" />
      <div>
        <h3 className="login-phone-form__header">Войти в личный кабинет</h3>
      </div>
      <div>
        <Form.Item>
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Checkbox className="login-phone-form__auto_sign" checked={autoSignin} onChange={(e) => setAutoSignin(e.target.checked)}>
            Запомнить меня
          </Checkbox>
        </Form.Item>

        <Button
          type="primary"
          size="large"
          block
          loading={loading}
          onClick={handlePasswordSubmit}
          className="button login-sms-code-form__submit-button"
        >
          Войти
        </Button>
      </div>
    </Form>
  ), [email, password, loading, handlePasswordSubmit, autoSignin]);

  const renderUserConfirmation = useCallback(() => (
    <Form className="login-phone-form">
      <div className="login-phone-form__mobile-spacer" />
      <div className="login-phone-form__logo" />
      <div className="login-phone-form__main-content">
        <div>
          <h3 className="login-phone-form__confirmation_header">
            <span>
              {loginMethod === "phone"
                ? (formatPhoneWithCountryCode(account?.phone) ? formatPhoneWithCountryCode(props.account?.phone) : props.account?.email)
                : (account?.email ? account?.email : formatPhoneWithCountryCode(props.account?.phone))
              }
            </span>
            <span>Это вы?</span>
          </h3>
        </div>
        <div className="login-phone-form__confirmation_button">
          <Button
            type="primary"
            size="large"
            block
            loading={loading}
            onClick={handleUserConfirmation}
            className="button login-phone-form__submit-button"
          >
            Да, это я
          </Button>
          <Button
            type="primary"
            size="large"
            block
            onClick={() => setStage("phone")}
            className="button login-phone-form__cancel-button"
          >
            Нет, войти как другой пользователь
          </Button>
        </div>
      </div>
    </Form>
  ), [props.account, loading, handleUserConfirmation, loginMethod, formatPhoneWithCountryCode]);

  const renderLoginForm = useCallback(() => {
    if (stage === "confirmation") {
      return renderUserConfirmation();
    }

    if (loginMethod === "phone") {
      if (stage === "phone") {
        return renderPhoneForm();
      } else if (stage === "code") {
        return renderCodeForm();
      } else if (stage === "registration") {
        return renderRegistrationForm();
      } else if (stage === "agreements") {
        return renderAgreementsForm();
      }
    } else {
      return renderPasswordForm();
    }
  }, [stage, loginMethod, renderPhoneForm, renderCodeForm, renderRegistrationForm, renderAgreementsForm, renderPasswordForm, renderUserConfirmation]);

  const items = useMemo(() => [
    {
      label: "По почте и паролю",
      key: "password",
    },
    {
      label: "По номеру телефона",
      key: "phone",
    },
  ], []);

  if (!application.name || !application.organization) {
    Setting.showMessage("error", "Ошибка: не удалось загрузить данные приложения");
    return null;
  }

  return (
    <div className="custom-login-page">
      <div className="custom-login-container">
        <div className="custom-login-container__content">
          {availableLoginMethods.length > 1 ? (
            <>
              <Tabs
                className="signin-methods"
                items={items}
                size="small"
                activeKey={loginMethod}
                onChange={(key) => {
                  setLoginMethod(key);
                  setStage((prev) => prev === "phone" ? "phone" : "confirmation");
                }}
                centered
              />
              {renderLoginForm()}
            </>
          ) : (
            renderLoginForm()
          )}
        </div>
        <CaptchaModal
          owner={application?.owner}
          name={application?.name}
          visible={captchaVisible}
          onOk={handleCaptchaOk}
          onCancel={handleCaptchaCancel}
          isCurrentProvider={false}
        />
      </div>
    </div>
  );
};

CustomLoginPage.propTypes = {
  application: PropTypes.shape({
    name: PropTypes.string,
    organization: PropTypes.string,
    owner: PropTypes.string,
    signupItems: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        required: PropTypes.bool,
      })
    ),
  }).isRequired,
  onLoginSuccess: PropTypes.func.isRequired,
};

export default CustomLoginPage;
